.dropzone{
    border: 0;
    border-radius: 0.5rem;
    min-height: auto;
}

.dropzone.dropzone-multiple .dz-message {
    padding: 2rem 1rem;
    border: 1px dashed #cbd0dd;
    border-radius: 0.5rem;
}
.dropzone .dz-message {
    text-align: center;
    // margin: 2em 0;
}
.dropzone.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
    cursor: pointer;
}

.inconImage{
    color: rgb(44, 42, 42);
    font-size: 2em;
    text-align: center;
}
.leftCard{
    margin-top: 2em;
}