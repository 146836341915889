html,
body {
  height: 100%;
}

.form-signin {
  max-width: 460px;
  padding: 1rem;
}





@media (min-width: 768px) {
  .bd-placeholder-img-lg {
    font-size: 3.5rem;
  }
}

.b-example-divider {
  width: 100%;
  height: 3rem;
  background-color: rgba(0, 0, 0, .1);
  border: solid rgba(0, 0, 0, .15);
  border-width: 1px 0;
  box-shadow: inset 0 .5em 1.5em rgba(0, 0, 0, .1), inset 0 .125em .5em rgba(0, 0, 0, .15);
}

.b-example-vr {
  flex-shrink: 0;
  width: 1.5rem;
  height: 100vh;
}






.bd-mode-toggle {
  z-index: 1500;
}

.logoEtriagnle {
  align-content: center;
  justify-content: center !important;
  display: flex !important;
  width:100%;
  height:80px;

.appLogo{
  background-color: white;
    border-radius: 50% !important;
    width:80px;
    height:80px;
}
}